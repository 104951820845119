import 'react-toastify/dist/ReactToastify.min.css'
import 'keen-slider/keen-slider.min.css'
import '../global.css'

import { app, createStore } from '@obvio/app'
import { Popup } from '@obvio/template'
import Script from 'next/script'

import { PopupContent } from '@/components/PopupContent'
import { YTContext } from '@/components/YTContext'

export const globalStore = createStore(
  {
    NEWSLETTER: (_, newState = true) => ({
      newsletter: newState,
    }),
  },
  { newsletter: false },
)

export default app({
  globalStoreHook: globalStore,
  contextPaths: {
    /* TODO: change to ecommerce contextPath when ready. */
    ecommerce: '/sklep',
    'ecommerce.product': '/sklep/produkt',
    'ecommerce.category': '/sklep/kategoria',
    'ecommerce.search': '/sklep',
    'ecommerce.checkout': '/sklep/zakupy',
    'ecommerce.userPanel': '/sklep/panel',
    'ecommerce.userPanel.wishlist': '/sklep/panel/ulubione',
    'ecommerce.userPanel.settings': '/sklep/panel/ustawienia',
    'ecommerce.userPanel.addresses': '/sklep/panel/adresy',
    signIn: '/logowanie',
    signUp: '/rejestracja',
    privacyPolicy: 'https://lustre.pl/polityka-prywatnosci/',
    tos: '/regulamin',
    forgotPassword: '/zresetuj-haslo',
  },
  seoData: {
    title: 'Poziom 511 Jura Wellness Hotel & SPA',
    canonical: `https://${process.env.NEXT_PUBLIC_DOMAIN ?? ''}`,
    description:
      'Poziom 511 Jura Wellness Hotel & SPA położony jest w samym sercu Jury Krakowsko-Częstochowskiej, u stóp Zamku Ogrodzieniec. Komfortowe pokoje hotelowe, basen, strefa SPA&Wellness, restauracja, sale konferencyjne i wiele więcej. Zapraszamy!',
    openGraph: {
      type: 'website',
      title: 'Poziom 511 Jura Wellness Hotel & SPA',
      description:
        'Poziom 511 Jura Wellness Hotel & SPA położony jest w samym sercu Jury Krakowsko-Częstochowskiej, u stóp Zamku Ogrodzieniec. Komfortowe pokoje hotelowe, basen, strefa SPA&Wellness, restauracja, sale konferencyjne i wiele więcej. Zapraszamy!',
      images: [
        {
          url: `https://www.poziom511.pl/static/img/poziom.jpeg`,
          width: 1200,
          height: 1200,
          type: `image/webp`,
          alt: 'Poziom',
        },
      ],
    },
  },
  enhance: (Component) => (props) => (
    <YTContext>
      <Script
        src="//code.jquery.com/jquery-3.6.0.slim.min.js"
        strategy="afterInteractive"
        async
      />
      <Script
        src="//open.upperbooking.com/poziom511jurawellnesshotelspa/Booking.js?locale=pl"
        strategy="afterInteractive"
        async
      />
      <div>
        <div id="fb-root" />
        <div id="fb-customer-chat" className="fb-customerchat" />
      </div>
      <Script id="fb-init" strategy="afterInteractive">
        {` var chatbox = document.getElementById('fb-customer-chat');
      chatbox.setAttribute("page_id", "186882341404612");
      chatbox.setAttribute("attribution", "biz_inbox");

				window.fbAsyncInit = function() {
					FB.init({
						xfbml            : true,
						version          : 'v16.0'
					});
				};

				(function(d, s, id) {
					var js, fjs = d.getElementsByTagName(s)[0];
					if (d.getElementById(id)) return;
					js = d.createElement(s); js.id = id;
					js.src = 'https://connect.facebook.net/pl_PL/sdk/xfbml.customerchat.js';
					fjs.parentNode.insertBefore(js, fjs);
				}(document, 'script', 'facebook-jssdk'));
			`}
      </Script>
      <Component {...props} />
      <Popup>{(data) => <PopupContent {...data} />}</Popup>
    </YTContext>
  ),
})
